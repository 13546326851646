import { Response } from './Response'

export class ApiResponse extends Response {
  toViewModel(ViewModal) {
    if (this.isError) {
      return this
    }
    this._computedData = new ViewModal(this.data)
    return this
  }

  /** @return {T} */
  get data() {
    return this._computedData || this._rawResponse.data.items
  }
}
