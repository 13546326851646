import axios from 'axios'

export class Resources {
  constructor(client, responseBuilder = null) {
    /** @type {import("axios").AxiosStatic} */
    this.client = client
    this._responseBuilder = responseBuilder

    client.CancelToken = axios.CancelToken
    client.isCancel = axios.isCancel

    this.source = this.client.CancelToken.source()
  }

  /**
   * @param {string} url
   * @param {import("axios").AxiosRequestConfig} config
   * @returns
   * @memberof Resources
   */
  get(url, config) {
    return this.client
      .get(url, {
        cancelToken: this.source.token,
        ...config,
      })
      .then((response) => this._buildResponse(response))
      .catch((err) => {
        if (this.client.isCancel()) {
          return this._buildResponse({
            status: 404,
          })
        }
        return this._buildResponse(err.response)
      })
  }

  /**
   * @param {string} url
   * @param {{ [k: string]: string | number }} data
   * @param {import("axios").AxiosRequestConfig} config
   * @returns
   * @memberof Resources
   */
  post(url, data, config) {
    return this.client
      .post(url, data, {
        cancelToken: this.source.token,
        ...config,
      })
      .then((response) => this._buildResponse(response))
      .catch((err) => {
        if (this.client.isCancel()) {
          return this._buildResponse({
            status: 404,
          })
        }
        return this._buildResponse(err.response)
      })
  }

  updateSource() {
    this.source = this.client.CancelToken.source()
  }

  _buildResponse(response) {
    if (typeof this._responseBuilder !== 'function') {
      return response
    }
    return this._responseBuilder(response)
  }
}
