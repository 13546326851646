/**
 * @template T
 */
export class Response {
  constructor(response) {
    this._rawResponse = response
    this._expectStatus = 200
  }

  get expectStatus() {
    return this._expectStatus
  }

  set expectStatus(expectStatus) {
    this._expectStatus = expectStatus
  }

  get status() {
    return this._rawResponse.status
  }

  /** @return {T} */
  get data() {
    return this._computedData || this._rawResponse.data
  }

  get isError() {
    return this.status > this.expectStatus
  }

  toViewModel(ViewModal) {
    if (this.isError) {
      return this
    }
    this._computedData = new ViewModal(this.data)
    return this
  }
}
