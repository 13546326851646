import { Response } from './Response'

export class JsonResponse extends Response {
  get isError() {
    return (
      this._rawResponse.headers['content-type'].includes('text/html') ||
      this.status > this.expectStatus
    )
  }
}
