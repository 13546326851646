import axios from 'axios'
import { Response } from './Response'
import { Resources } from './Resources'
import { JsonResponse } from './JsonResponse'
import { CsvResponse } from './CsvResponse'
import { ApiResponse } from './ApiResponse'
import { RequestCanceler } from './RequestCanceler'

const client = axios.create()

export default new Resources(client, (response) => new Response(response))

export const requestJson = new Resources(
  axios.create({
    baseURL: '/data/json',
  }),
  (response) => new JsonResponse(response)
)

export const requestCsv = new Resources(
  axios.create({
    baseURL: '/',
    responseType: 'text',
  }),
  (response) => new CsvResponse(response)
)

export const requestApi = new Resources(
  axios.create({
    baseURL: process.env.VUE_APP_SECURE_API_BASE_URL,
    headers: {
      'X-Api-Key': process.env.VUE_APP_SECURE_API_KEY,
    },
  }),
  (response) => new ApiResponse(response)
)

export const requestPublicApi = new Resources(
  axios.create({
    baseURL: process.env.VUE_APP_PUBLIC_API_BASE_URL,
    headers: {
      'X-Api-Key': process.env.VUE_APP_PUBLIC_API_KEY,
    },
  }),
  (response) => new ApiResponse(response)
)

export const requestCanceler = new RequestCanceler(
  requestJson,
  requestApi,
  requestCsv,
  requestPublicApi
)
