export class RequestCanceler {
  constructor(requestJson, requestApi, requestCsv, requestPublicApi) {
    this.requestJson = requestJson
    this.requestApi = requestApi
    this.requestCsv = requestCsv
    this.requestPublicApi = requestPublicApi
  }

  cancel() {
    this.requestJson.source.cancel()
    this.requestApi.source.cancel()
    this.requestCsv.source.cancel()
    this.requestPublicApi.source.cancel()
    this.requestJson.updateSource()
    this.requestApi.updateSource()
    this.requestCsv.updateSource()
    this.requestPublicApi.updateSource()
  }

  token() {
    return {
      requestJson: this.requestJson.source,
      requestApi: this.requestApi.source,
      requestCsv: this.requestCsv.source,
      requestPublicApi: this.requestPublicApi.source,
    }
  }
}
